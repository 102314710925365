<template>
  <div class="wrap">
    <nav-bar :title="$t('team.InviteRewards')"></nav-bar>

    <van-tabs v-model="active" color="#2D82FA" sticky>
      <van-tab :title="$t('team.MyInvitation')">
        <div>
          <van-field v-model="link" :name="$t('team.InviteLink')" :label="$t('team.InviteLink')" :placeholder="$t('team.InviteLink')" />
          <van-field v-model="inviteCode" :name="$t('team.InviteCode')" :label="$t('team.InviteCode')" :placeholder="$t('team.InviteCode')" />

          <div style="margin: 16px;"></div>
        </div>

        <div class="op_container">
          <van-button
            plain
            type="info"
            class="btn1 leftBtn"
            :data-clipboard-text="link"
            @click="copy"
            size="small"
          >{{$t('team.CopyInvitationAddress')}}</van-button>
          <!-- <van-button type="info" size="small"></van-button> -->

          <van-button plain type="info" class="btn2 rightBtn" @click="gohaibao" size="small">{{$t('team.FaceFaceInvitation')}}</van-button>

          <!-- <van-button class='btn2' size="large">保存到相册</van-button> -->
        </div>

        <div class="codeBox" id="captureId">
          <!-- <img src="" alt="" /> -->
          <h3>{{$t('team.SweepCodeToInvite')}}</h3>
          <div id="qrcode" ref="qrcode"></div>
        </div>

        <van-divider>{{$t('team.MyReward')}}</van-divider>
        <van-field v-model="inviteNum" :name="$t('team.InviteNumber')" :label="$t('team.InviteNumber')" :placeholder="$t('team.InviteNumber')" />

        <van-field v-model="reward" :name="$t('team.InviteReward')" :label="$t('team.InviteReward')" :placeholder="$t('team.InviteReward')" />

        <van-divider>{{$t('team.rankingList')}}</van-divider>
      </van-tab>

      <van-tab :title="$t('team.MyReward')">
        <div class="send_red" v-for="(item,index) in receiveRedList" :key="index">
          <div class="send_red_text">
            <span>{{$t('team.redPacket')}}:</span>
            <span class="g">{{item.amount}}{{$t('team.individual')}}{{item.token}}</span>
          </div>

          <!-- <div class="send_red_text">
            <span>红包来源:</span>
            <span class="g">{{item.username}}</span>
          </div>-->

          <div class="send_red_text">
            <span>{{$t('team.time')}}:</span>
            <span class="g">{{item.created_at}}</span>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <van-popup v-model="shareImgShow">
      <img class="share_img" :src="dataURL" alt v-show="firstFlag" />
    </van-popup>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { Divider } from "vant";

export default {
  name: "team",
  props: ["num"],
  data() {
    return {
      active: 0, // 我发布/收到 切换
      loading: false,
      finished: false,
      LoadingFlag: true,
      sendPage: 1,
      refcode: "",
      link: "",
      firstFlag: true,
      dataURL: "",
      shareImgShow: false,
      receivePage: 1,
      inviteCode: 0,
      inviteNum: 0,
      reward: 0,
      inviteList: [],
      sendRedList: [], // 我发出的红包 数据 列表
      receiveRedList: [], // 我收到的红包 数据 列表
    };
  },
  watch: {},
  methods: {
    gohaibao() {
      this.$router.push({
        path: "/haibao",
        query: {
          link: this.link,
          inviteCode: this.inviteCode,
        },
      });
    },
    getInviteCode() {
      this.$post2("user/api/Index/getMyInviteCode", {})
        .then((res) => {
          this.link = res.link;
          this.inviteCode = res.refcode;
          this.qrcode();
        })
        .catch((err) => {
          this.showAlert(err);
        });
    },
    showAlert(msg) {
      this.$dialog.alert({
        title: this.$t('team.hint'),
        message: msg,
        confirmButtonText: this.$t('team.affirm')
      });
    },
    inviteFace() {
      let c = document.getElementById("captureId");
      c.style.display = "block";
      c.style.visibility = "visible";

      html2canvas(document.querySelector("#captureId"))
        .then((canvas) => {
          let imgUrl = canvas.toDataURL("image/png");
          this.dataURL = imgUrl;
          this.shareImgShow = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    qrcode() {
      let canvaswidth = this.$refs.qrcode.clientWidth * 1;
      //  let     canvaswidth = window.getComputedStyle(this.$refs.qrcode).height;

      // console.log(canvaswidth);
      let qrcode = new QRCode("qrcode", {
        width: canvaswidth, // 设置宽度，单位像素
        height: canvaswidth, // 设置高度，单位像素
        text: this.link, // 设置二维码内容或跳转地址
      });
    },

    copy() {
      var clipboard = new Clipboard(".btn1");
      clipboard.on("success", (e) => {
        this.$dialog.alert({
          message: this.$t('team.copySuccess'),
          confirmButtonText: this.$t('team.affirm')
        });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
  created() {
    this.getInviteCode();
  },
};
</script>

<style lang="less" scoped>
// 我写的less
.wrap {
  // 保存出来的分享图
  .van-popup {
    border-radius: 15px;
    background: darkolivegreen;
    padding: 5px;

    width: 70%;
    display: flex;
    flex-direction: column;
    .share_img {
      width: 100%;
    }
    span {
      text-align: center;
      font-size: 0.3rem;
    }
    #qrcode {
      padding: 0.2rem;
    }
  }
}
.op_container {
  margin: 0 auto;
  text-align: center;
  // flex-direction: row;
  display: flex;
  justify-content: center; /* 水平居中 */
}
.codeBox {
  width: 2.73rem;
  height: 3rem;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  margin: 0 auto;
  img {
    width: 90%;
  }
}

#captureId {
  visibility: hidden;
  float: left;
  margin-top: -10rem;
}
.leftBtn {
  width: 35%;
  margin-left: 10px;
  float: left;
  margin-right: 1rem;
}
.rightBtn {
  width: 35%;
  margin-right: 10px;
  float: right;
}
</style>
